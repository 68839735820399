import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Permissions from "../../common/config/permissions";
import Restricted from "../../common/context/hooks/Restricted";
import CustomButton from "../../common/layouts/Global/CustomButton";
import Dropdown from "../../common/layouts/Global/Dropdown";
import { RoundButtonWrench } from "../../common/layouts/Global/RoundButton";
import ScrollBarArrow from "../../common/layouts/Global/ScrollBarArrow";
import WhiteBlockDiv from "../../common/layouts/Global/WhiteBlockDiv";
import { ToastNotSaved, ToastSaved } from "../../common/util/ToastUtil";
import ModifyVideosPopup from "../layouts/Dashboard/Popup/ModifyVideosPopup";
import Sequencie from "../layouts/OneDrive/Sequencie";
import Video from "../layouts/OneDrive/Video";
import {
	addSequence,
	deleteSequence,
	editSequence,
	getGallery,
	getSequence,
	getSequences,
} from "../requests/video/videoRequest";
function Gallery() {
	const { groupId } = useParams();
	const { t } = useTranslation();

	const { data: gallery, refetch: refetchGalery } = useQuery(
		[`group-${groupId}-gallery`],
		{
			queryFn: () => getGallery(groupId),
		}
	);

	const [originDiv, setoriginDiv] = useState(false);
	const [checkArrow, setCheckArrow] = useState(false);
	const [cancel, setCancel] = useState(false);
	const { data: sequences, refetch: refetchSequences } = useQuery(
		[`group-${groupId}-sequences`],
		{
			queryFn: () => getSequences(groupId),
		}
	);
	const [sequence, setSequence] = useState(null);
	const [droppedItems, setDroppedItems] = useState(sequence?.sequence);
	const [modifyPopUp, setModifyPopUp] = useState(false);

	useEffect(() => {
		setSequence(null);
		setDroppedItems(null);
		refetchSequences();
	}, [groupId, refetchSequences]);
	const closePopUp = () => {
		setModifyPopUp(false);
	};

	return (
		<WhiteBlockDiv
			className="mb-2 sm:mb-0"
			text={t("gallery")}
			text2={
				<Restricted to={Permissions.GROUP_VIDEO_EDIT} groupId={groupId}>
					<RoundButtonWrench
						icon={faWrench}
						functionCall={() => {
							setModifyPopUp(true);
						}}
					/>
				</Restricted>
			}
		>
			{gallery.success && (
				<div className="flex flex-col h-full w-full ">
					<ScrollBarArrow border={false}>
						<Video
							loading={gallery.success ? gallery?.data : []}
							setoriginDiv={setoriginDiv}
							refetchGalery={refetchGalery}
						/>
					</ScrollBarArrow>

					{sequences && (
						<div className="pl-6 my-2">
							<Dropdown
								key={groupId}
								customTitle={t("sequence")}
								list={sequences?.map((elem) => ({
									title: elem?.name,
									id: elem?.id,
									onClick: async () => {
										const sequence = await getSequence(
											groupId,
											elem?.id
										);
										setSequence(sequence);
										setCancel(false);
									},
								}))}
								adding={{
									title: t("create"),
									function: async (el) => {
										const data = await addSequence(
											groupId,
											el
										);
										if (data.success) {
											setSequence(data.data);
											refetchSequences();
										}
										return data;
									},
								}}
							/>
						</div>
					)}
					<div>
						<ScrollBarArrow checkArrow={checkArrow} border={true}>
							<Sequencie
								key={groupId}
								sequence={sequence?.sequence}
								draggedItem={gallery?.data}
								droppedItems={droppedItems}
								setDroppedItems={setDroppedItems}
								setoriginDiv={setoriginDiv}
								originDiv={originDiv}
								setCancel={setCancel}
								setCheckArrow={setCheckArrow}
							/>
						</ScrollBarArrow>

						<div className="flex flex-col md:flex-row-reverse md:px-10">
							{sequence && (
								<>
									<div className="p-2">
										<CustomButton
											type="delete"
											className="font-bold md:w-40 w-full"
											onClick={async () => {
												const deleteSequenc =
													await deleteSequence(
														groupId,
														sequence.id
													);
												if (deleteSequenc.success) {
													ToastSaved();
													setCancel(false);
												} else {
													ToastNotSaved();
												}
											}}
											text={t("delete")}
										/>
									</div>
									<div className="p-2">
										<CustomButton
											type="add"
											className="font-bold md:w-40 w-full"
											onClick={async () => {
												const editSequenc =
													await editSequence(
														groupId,
														sequence.id,
														droppedItems?.map(
															(elem) =>
																elem.realId
														)
													);
												setCancel(false);
												if (editSequenc.success) {
													ToastSaved();
												} else {
													ToastNotSaved();
												}
											}}
											text="SAUVEGARDE"
										/>
									</div>
								</>
							)}

							{cancel && (
								<div className="p-2">
									<CustomButton
										type="cancel"
										className=" font-bold md:w-40 w-full"
										onClick={async () => {
											const sequenc = await getSequence(
												groupId,
												sequence.id
											);
											setSequence(sequenc);
											setCancel(false);
										}}
										text="ANNULER"
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			<ModifyVideosPopup
				open={modifyPopUp}
				closeMethod={closePopUp}
				groupId={groupId}
				refetch={refetchGalery}
			/>
		</WhiteBlockDiv>
	);
}

export default Gallery;

const SCORES = {
	paymentMachine: {
		score: 50,
		classNames: [],
	},
	"qr-scanner": {
		score: 50,
		classNames: [],
	},
	"arduino-mega": {
		score: 50,
		classNames: [],
	},
	"arduino-uno": {
		score: 50,
		classNames: [],
	},
	touchscreen: {
		score: 50,
		classNames: [],
	},
	"water-temperature": {
		score: 500,
		classNames: ["bg-yellow-100"],
	},
	"water-flow": {
		score: 1000,
		classNames: ["bg-yellow-100"],
	},
	"water-sensor": {
		score: 1000,
		classNames: ["bg-blue-100"],
	},
	"water-leak": {
		score: 1000,
		classNames: ["bg-blue-100"],
	},
};

function GetMachineStatus(machine) {
	const status = {
		score: 0,
		className: "bg-ghostwhite",
	};

	for (const item of machine.hardware_status) {
		if (item.status !== true) {
			const score = SCORES[item.key];

			if (!score) continue;

			status.score += score.score;
			if (score.classNames.length > 0) {
				status.className = score.classNames.join(" ");
			}
		}
	}

	if (!machine.isOnline) {
		status.score += 25;
	}

	if (!machine.isVideoOnline) {
		status.score += 25;
	}

	return status;
}

export { GetMachineStatus };
